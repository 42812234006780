@import "../../vars";

.Indicator {
  @extend %flex;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
  border-radius: 8px;
  color: $col-dark;
  text-decoration: none;
  transition: background .2s;

  &._country {
    background: $col-gray;
    cursor: pointer;

    &:hover {
      background: $col-gray2;
    }
  }
}
.Indicator__icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 12px;
  margin-right: 16px;

  background-repeat: no-repeat;
  background-size: cover;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.Indicator__content {
  width: 100%;
  p {
    margin: 0;
  }
}
.Indicator__title {
  opacity: .5;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
.Indicator__value {
  p {
    font-weight: 700;
    line-height: 24px;
  }
}
.Indicator__arrow {
  width: 24px;
  min-width: 24px;
  height: 24px;
  color: $col-gray-d2;
  @extend %svg-icon;

  transform: scale(-1, 1);
}
