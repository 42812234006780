@import "../../vars";

.Document {
  max-width: 220px;
  cursor: pointer;
  display: inline-block;

  p {
    margin: 0;
  }
}
.Document__link {
  @extend %flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.Document__icon {
  width: 56px;
  min-width: 56px;
  margin-right: 16px;

  img {
    display: block;
    width: 100%;
  }
}
.Document__info {
  min-height: 36px;
}
.Document__title {
  margin-bottom: 4px;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
.Document__size {
  opacity: .3;
  p {
    font-size: 11px;
    line-height: 16px;
  }
}
