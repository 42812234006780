@import "../../../vars";

.Event {
  width: 100%;
  max-width: var(--event-max-width);
  background: $col-white;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgba(39,44,58,0.05);
  overflow: hidden;
  cursor: pointer;
  transition: background .2s;

  &._selected {
    background: $col-gray2;
  }
}
.Event__img {
  height: 168px;
  background-repeat: no-repeat;
  background-size: cover;
}
.Event__content {
  padding: 16px 20px 24px;

  p {
    margin: 0;
  }
}
.Event__title {
  p {
    font-weight: 700;
    line-height: 24px;
  }
}
.Event__desc {
  margin-top: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: $col-gray-d2;

  p, ul, ol {
    margin: 0;
  }
}
