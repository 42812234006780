
$col-black: #000;
$col-white: #fff;
$col-dark: #272C3A;
$col-gray: #F6F8FA;
$col-gray2: #E7EBF0;
$col-gray-d: #7A8C99;
$col-gray-d2: #676B75;
$col-blue: #0056FF;
$col-red: #FF6464;
