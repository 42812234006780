@import "../../../vars";

.EventsList {
  //@extend %flex;
  //justify-content: center;

  &._firstloading {
    .EventsList__grid {
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }
  }
  &._loading {
    .EventsList__grid {
      padding-bottom: 0;
    }
  }
  &._empty {
    .EventsList__grid {
      display: none;
    }
  }
}
.EventsList__grid {
  position: relative;
  @extend %grid-center;
  grid-template-rows: auto;
  grid-row-gap: 0;
  width: 100%;
  max-width: var(--grid-center-width);
  padding-bottom: var(--eventslist-bottom-padd);
  margin: 0 auto;

  &:after {
    position: absolute;
    content: '';
    top: calc(var(--event-dot-offset-width) / 2);
    bottom: 0;//invert(var(--eventslist-bottom-padd));
    left: 50%;
    width: 2px;
    margin-left: -1px;
    background: $col-gray-d;
    opacity: .3;
  }
}
.EventsList__events {
  position: relative;
  grid-column: 1 / span 8;
  //min-height: 125px;
  @extend %clear;
}
.EventsList__eventsSide {
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 1px;
  float: left;
  pointer-events: none;
}
.EventsList__eventsList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @extend %clear;
}
.EventsList__ext {
  //grid-column: 1 / span 8;
}
.EventsList__empty {
  text-align: center;
  padding-bottom: 20px;
  p {

  }
}
.EventsList__showMore {
  padding-bottom: 32px;
}
.EventsList__moreBtn {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}
//.EventsList__loader {
//  grid-column: 1 / span 8;
//}

.EventsList__event {
  //display: none;
  position: absolute;
  top: 0;
  width: 50%;

  &._left {
    left: 0;
    padding-right: calc(var(--grid-col-gap) / 2);

    .EventsList__eventDot {
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: calc(var(--grid-col-gap) / -2 - var(--event-dot-offset-width) / 2);
    }
    .EventsList__eventIcon {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  &._right {
    right: 0;
    padding-left: calc(var(--grid-col-gap) / 2);
  }
}

.EventsList__eventDot {
  @extend %flex;
  align-items: center;
  margin-bottom: 8px;
  margin-left: calc(var(--grid-col-gap) / -2 - var(--event-dot-offset-width) / 2);

  p {
    margin: 0;
  }
}
.EventsList__eventIcon {
  margin-right: 8px;
}
.EventsList__eventIcon__icon {
  &:before {
    background: $col-gray;
  }
}
.EventsList__eventDate {
  opacity: .5;
  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}

.Event {
  margin: 0 auto;
}


@media only screen {
  @media (max-width: $bp-tablet-max-width) {

    .EventsList__event {
      &._left {
        .Event {
          float: left;
        }
      }
      &._right {
        .Event {
          float: right;
        }
      }
    }

  }
  @media (max-width: $bp-mobile-max-width) {

    .EventsList__grid {
      &:after {
        display: none;
      }
    }
    .EventsList__eventsSide {
      display: none;
    }
    .EventsList__eventsList {
      position: relative;
      padding: 0 var(--wrapper-side-padd);
    }
    .EventsList__event {
      position: relative;
      top: 0!important;
      width: 100%;
      max-width: 296px;
      margin-bottom: 44px;
      &:last-child {
        margin-bottom: 0;
      }

      &._left {
        clear: both;
        float: left;
        padding-right: 0;

        .EventsList__eventDot {
          flex-direction: row;
        }
        .EventsList__eventIcon {
          margin-left: 0;
          margin-right: 8px;
        }
      }
      &._right {
        clear: both;
        float: right;
        padding-left: 0;

        .EventsList__eventDot {
          flex-direction: row-reverse;
        }
        .EventsList__eventIcon {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }

    .EventsList__eventDot {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
