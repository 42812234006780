@import "../../vars";

.LoginPage {
  height: 100vh;
}
.LoginPage__Layout {
  padding-top: 0;
}

.LoginPage__login {
  grid-column: 5 / span 4;
  @extend %flex-c;
}
.LoginPage__loginBox {
  width: 100%;
}

.LoginForm {
  padding: 40px 56px;

  &._error {
    .LoginForm__input {
      input {
        border-color: $col-red;
      }
    }
  }
}
.LoginForm__title {
  text-align: center;
  margin-bottom: 48px;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
  }
}
.LoginForm__line {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.LoginForm__button {
  width: 100%;
}
.LoginForm__error {
  margin-top: 10px;
  color: $col-red;
}


@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    .LoginPage {
      background: $col-white;
    }
    .LoginPage__login {
      grid-column: 1 / span 12;
    }
    .LoginPage__loginBox {
      box-shadow: none;
    }

  }
}
