@import "../../vars";

.DatePeriod {
  @extend %flex;
  align-items: center;

  > i {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
}
.DatePeriod__dates {
  @extend %flex;
  align-items: center;

  p {
    line-height: 24px;
    margin: 0;
  }
}
.DatePeriod__date {

}
