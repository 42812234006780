@import "../../vars";

.TopBar {
  position: fixed;
  z-index: var(--zindex-topbar);
  top: 0;
  left: 0;
  width: 100%;
  height: var(--topbar-height);
  background: $col-white;
}
.TopBar__inner {
  @extend %flex-btw;
  position: relative;
  z-index: 1;
  background: $col-white;
  border-bottom: 1px solid rgba($col-gray-d, .2);
}
.TopBar__main {
  @extend %flex;
  align-items: center;
}

.TopBar__navBtn {
  @extend %flex-c;
  width: var(--topbar-height);
  min-width: var(--topbar-height);
  height: var(--topbar-height);
  border-right: 1px solid rgba($col-gray-d, .2);
  cursor: pointer;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
}
.TopBar__title {
  margin-left: 32px;

  p {
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }
}
.TopBar__filter {
  margin-left: 32px;
}

.TopBar__ext {
  padding-right: 32px;
}

.TopBarUser {
  position: relative;
}
.TopBarUser__content {
  @extend %flex;
  align-items: center;
  cursor: pointer;
}
.TopBarUser__ava {
  position: relative;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
}
.TopBarUser__indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  //margin-top: -2px;
  //margin-right: -2px;
  border-radius: 50%;
  background: $col-red;
  border: 2px solid $col-white;
}
.TopBarUser__title {
  @extend %flex;
  align-items: center;
  margin-left: 12px;
  color: $col-gray-d;

  p {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
.TopBarUser__navIcon {
  width: 16px;
  min-width: 16px;
  height: 16px;

  @extend %svg-icon;
}

.TopBarUser__menu {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 100%;
  padding: 10px;
  margin-top: 8px;
  background: $col-white;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(39, 44, 58, .2);
  text-align: center;
}
.TopBarUser__logoutBtn {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $col-blue;
  cursor: pointer;
}


@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    .TopBar__main {
      width: 100%;
    }
    .TopBar__title {
      width: 100%;
      //height: var(--topbar-height);
      text-align: center;
      margin: 0;
      padding-right: var(--topbar-height);
    }

    .TopBar__filter {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: var(--topbar-height);
      padding: 0 var(--wrapper-side-padd);
      margin-left: 0;
      border-bottom: 1px solid rgba($col-gray-d, .2);
      background: $col-white;
      transform: translateY(-100%);
      transition: transform .2s;

      display: flex;
      align-items: stretch;

      &._visible {
        transform: translateY(0);
      }
    }
    .ProtestFilter__select {
      height: 100% !important;

      :global {
        .select__menu {
          min-width: 100% !important;
          margin-left: 0!important;
        }
      }
    }

    .TopBarUser__menu {
      right: auto;
      left: 0;
      padding: 16px 20px;
      margin-left: 10px;
      min-width: 150px;
    }

  }
}
