@import "../../vars";


$event-dot-offset-width: 24px;
$event-dot-width: 10px;
$event-dot-lg-width: 20px;

.EventIcon {
  position: relative;
  z-index: 1;
  width: var(--event-dot-offset-width);
  height: var(--event-dot-offset-width);
  transition: color .2s;

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    transition: background .2s, opacity .2s;
  }

  &._red {
    color: $col-red;
    .EventIcon__circle {
      border-color: $col-red;
    }
  }
  &._blue {
    color: $col-blue;
    .EventIcon__circle {
      border-color: $col-blue;
    }
  }

  &._violence {
    &:before {
      width: var(--event-dot-offset-width);
      height: var(--event-dot-offset-width);
      margin-top: calc(var(--event-dot-offset-width) / -2);
      margin-left: calc(var(--event-dot-offset-width) / -2);
    }
    .EventIcon__circle {
      width: var(--event-dot-lg-width);
      height: var(--event-dot-lg-width);
      margin-top: calc(var(--event-dot-lg-width) / -2);
      margin-left: calc(var(--event-dot-lg-width) / -2);
    }
  }

  &._active {
    color: $col-white;
    &:after {
      opacity: .2;
    }

    &._red {
      .EventIcon__circle {
        background: $col-red;
      }
      &:after {
        background: $col-red;
      }
    }
    &._blue {
      .EventIcon__circle {
        background: $col-blue;
      }
      &:after {
        background: $col-blue;
      }
    }
  }
}
.EventIcon__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--event-dot-width);
  height: var(--event-dot-width);
  border: 2px solid transparent;
  border-radius: 50%;
  margin-top: calc(var(--event-dot-width) / -2);
  margin-left: calc(var(--event-dot-width) / -2);
  transition: background .2s;
}
.EventIcon__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  margin: -7px 0 0 -7px;

  @extend %svg-icon;
}
