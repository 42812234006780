@import "./vars";

html {
  font-family: var(--fonts-default);
  font-size: 16px;
  box-sizing: border-box;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $col-gray;
  color: $col-dark;
}

body {
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1 {
  margin: 0;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}
h2 {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html._right-panel-active {
  height: 100%;

  body {
    height: 100%;
    overflow: hidden;
  }
}

#wrapper-offset {
  position: relative;
  padding: 0 var(--wrapper-side-padd);
  overflow: hidden;
}

.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}




@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    h2 {
      font-size: 20px;
      line-height: 32px;
    }

    #wrapper-offset {
      padding: 0;
    }

  }
}

