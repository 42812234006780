@import './colors';

$font-museo: 'museo';
$fonts-default: $font-museo, 'Arial', 'Tahoma', sans-serif;

// breakpoints

// TODO mobile 450px && tablet 781px

$bp-mobile-min-width: 320px;
$bp-mobile-max-width: 450px;

$bp-tablet-max-width: 800px;


:root {
  --font-museo: #{$font-museo};
  --fonts-default: var(--font-museo), 'Arial', 'Tahoma', sans-serif;

  --topbar-height: 64px;

  --grid-col-amount: 12;
  --grid-col-width: 80px;
  --grid-col-gap: 24px;
  --grid-row-gap: 16px;
  --grid-width: calc((var(--grid-col-width) + var(--grid-col-gap)) * var(--grid-col-amount) - var(--grid-col-gap));

  --grid-center-col-amount: 8;
  --grid-center-width: calc((var(--grid-col-width) + var(--grid-col-gap)) * var(--grid-center-col-amount) - var(--grid-col-gap));

  --wrapper-side-padd: 12px;
  --content-side-padd: 56px;

  --eventslist-bottom-padd: 50px;
  --event-dot-offset-width: 24px;
  --event-dot-width: 10px;
  --event-dot-lg-width: 20px;

  --event-max-width: 376px;

  --right-panel-width: 576px;
  --right-panel-side-padd: 32px;

  --input-height: 48px;
  --button-height: 48px;

  --zindex-right-panel-offset: 1000;
  --zindex-right-panel: 1001;
  --zindex-topbar: 100;
  --zindex-indicator: 99;
}

@function px($v) {
  @return $v / 16 * 1em;
}
@function rpx($v) {
  @return $v / 16 * 1rem;
}
@function invert($v) {
  @return calc(-1 * #{$v});
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
@mixin horizontallyCenteredImage {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 100%;
  right: 0;
  transform: translateX(-50%);
}
@mixin verticallyCenteredImage {
  position: absolute;
  top: 0;
  margin: auto;
  bottom: 0;
}
@mixin buttonReset {
  padding: 0;
  border: none;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background: none;
  text-align: left;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

%flex {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
%flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
%flex-str {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
%clear {
  &:before,
  &:after {
    display: table;
    content: '';
    height: 0;
    opacity: 0;
    visibility: hidden;
    clear: both;
  }
}

%list-simple {
  margin: 0;
  padding: 0;
  list-style: none;
}
%grid-default {
  display: grid;
  grid-template-columns: repeat(var(--grid-col-amount), 1fr);
  grid-column-gap: var(--grid-col-gap);
  grid-row-gap: var(--grid-row-gap);
}
%grid-center {
  display: grid;
  grid-template-columns: repeat(var(--grid-center-col-amount), 1fr);
  grid-column-gap: var(--grid-col-gap);
  grid-row-gap: var(--grid-row-gap);
}

%button-reset {
  @include buttonReset;
}
%svg-icon {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
%link-reset {
  display: block;
  color: inherit;
  text-decoration: none;
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}
%input-reset {
  outline: none;
  resize: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  appearance: none;
  font-family: var(--fonts-default);
  font-size: 16px;
  color: $col-gray;
  @include placeholder {
    text-overflow: ellipsis;
    transition: color .3s;
  }
  &:focus {
    @include placeholder {
      text-overflow: clip;
      color: transparent!important;
    }
  }
}

:export {
  mediaWidthMobile: $bp-mobile-max-width;
  mediaWidthTablet: $bp-tablet-max-width;
}


@media only screen {
  @media (max-width: $bp-tablet-max-width) {

    :root {

      --event-max-width: 352px;

    }

  }
  @media (max-width: $bp-mobile-max-width) {

    :root {

      --content-side-padd: 16px;

      --topbar-height: 48px;

      --right-panel-side-padd: 16px;

    }

  }
}
