@import "../../vars";

.Loader {
  position: relative;
  @extend %flex-c;
}

@keyframes f_fadeG {
  0%{
    background: $col-blue;
  }

  100%{
    background: transparent;
  }
}

.Loader__spinner {
  position:relative;
  width:125px;
  height:125px;
  margin:auto;
  transform:scale(.4);
}
.Loader__dot {
  position:absolute;
  background: transparent;
  height:22px;
  width:22px;
  border-radius:12px;
  animation-name:f_fadeG;
  animation-duration:1.2s;
  animation-iteration-count:infinite;
  animation-direction:normal;

  &._dot1 {
    left:0;
    top:51px;
    animation-delay:0.45s;
    -o-animation-delay:0.45s;
    -ms-animation-delay:0.45s;
    -webkit-animation-delay:0.45s;
    -moz-animation-delay:0.45s;
  }
  &._dot2 {
    left:15px;
    top:15px;
    animation-delay:0.6s;
    -o-animation-delay:0.6s;
    -ms-animation-delay:0.6s;
    -webkit-animation-delay:0.6s;
    -moz-animation-delay:0.6s;
  }
  &._dot3 {
    left:51px;
    top:0;
    animation-delay:0.75s;
    -o-animation-delay:0.75s;
    -ms-animation-delay:0.75s;
    -webkit-animation-delay:0.75s;
    -moz-animation-delay:0.75s;
  }
  &._dot4 {
    right:15px;
    top:15px;
    animation-delay:0.9s;
    -o-animation-delay:0.9s;
    -ms-animation-delay:0.9s;
    -webkit-animation-delay:0.9s;
    -moz-animation-delay:0.9s;
  }
  &._dot5 {
    right:0;
    top:51px;
    animation-delay:1.05s;
    -o-animation-delay:1.05s;
    -ms-animation-delay:1.05s;
    -webkit-animation-delay:1.05s;
    -moz-animation-delay:1.05s;
  }
  &._dot6 {
    right:15px;
    bottom:15px;
    animation-delay:1.2s;
    -o-animation-delay:1.2s;
    -ms-animation-delay:1.2s;
    -webkit-animation-delay:1.2s;
    -moz-animation-delay:1.2s;
  }
  &._dot7 {
    left:51px;
    bottom:0;
    animation-delay:1.35s;
    -o-animation-delay:1.35s;
    -ms-animation-delay:1.35s;
    -webkit-animation-delay:1.35s;
    -moz-animation-delay:1.35s;
  }
  &._dot8 {
    left:15px;
    bottom:15px;
    animation-delay:1.5s;
    -o-animation-delay:1.5s;
    -ms-animation-delay:1.5s;
    -webkit-animation-delay:1.5s;
    -moz-animation-delay:1.5s;
  }
}

