@import "../../vars";

.PhotoSlider {
  position: relative;
  color: $col-white;

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    pointer-events: none;

    opacity: .6;
    box-shadow: inset 0 -45px 40px -30px $col-black;
  }
}
.PhotoSlider__slick {
  height: 100%;

  & :global {
    .slick-list {
      height: 100%;
    }
    .slick-track {
      align-items: stretch;
      height: 100%;
    }
    .slick-slide {
      > div {
        height: 100%;
      }
    }
  }
}
.PhotoSlider__slide {
  position: relative;
  height: 100%;
}


.PhotoSliderVideo {
  position: relative;
  height: 100%;
  cursor: pointer;

  &:hover {
    .PhotoSliderVideo__play {
      background: $col-white;
    }
  }

  &._playing {
    .PhotoSliderVideo__play {
      opacity: 0;
    }
  }
}
.PhotoSliderVideo__play {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  border-radius: 50%;
  background: rgba($col-white, .5);
  color: $col-dark;
  transition: background .2s, opacity .2s;
  will-change: opacity;
  @extend %flex-c;
  @extend %svg-icon;

  i {
    width: 32px;
    height: 32px;
  }
}

.PhotoSlider__image,
.PhotoSlider__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PhotoSlider__arrows {
  position: absolute;
  z-index: 2;
  bottom: 16px;
  left: 0;
  width: 100%;
  @extend %flex-c;
  pointer-events: none;
  user-select: none;
}
.PhotoSlider__arrow {
  position: relative;
  width: 24px;
  height: 24px;
  pointer-events: auto;
  cursor: pointer;
  @extend %svg-icon;

  i {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &._next {
    i {
      transform: scale(-1, 1);
    }
  }
}
.PhotoSlider__counter {
  min-width: 72px;
  margin: 0 16px;
  text-align: center;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
  }
}
