@import "../../vars";

.Dropdown {
  &._opened {
    .Dropdown__arrow {
      transform: rotate(180deg);
    }
  }
}
.Dropdown__head {
  position: relative;
  //min-height: 80px;
  padding: 16px 0;
  padding-right: 24px + 16px;
  cursor: pointer;
}
.Dropdown__title {
  overflow: hidden;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
}
.Dropdown__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  transition: transform .2s;

  @extend %svg-icon;
}

.Dropdown__dd {
  //border-bottom: 1px solid rgba($col-gray-d, .2);
}
.Dropdown__content {
  line-height: 20px;
  p {
    margin: 8px 0;
  }
  ul, ol {
    margin: 8px 0;
  }
  ul {
    list-style-type: disc;
  }
}
