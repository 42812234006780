@import "../../../vars";

.TitleBox {
  @extend %grid-default;
  width: 100%;
}
.TitleBox__content {
  grid-column: span 7;
  padding: 40px var(--content-side-padd) 48px;
}
.TitleBox__slider {
  grid-column: span 5;
  width: 100%;
  height: 100%;
}

.TitleBox__title {
  margin-bottom: 20px;
  p {
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    margin: 0;
  }
}

.TitleBox__indicators {
  margin-top: 24px;
  max-width: 280px * 2 + 40px;
}
.TitleBox__indicatorsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 16px;
  grid-auto-flow: row;

  @extend %list-simple;
  width: 100%;
}

.TitleSlider {
  height: 100%;
  width: 100%;
}
.TitleBox__image {
  height: 100%;
  //border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}


@media only screen {
  @media (max-width: $bp-tablet-max-width) {

    .TitleBox {
      grid-template-rows: auto auto;
      grid-template-columns: 100%;
      grid-row-gap: 0;
    }
    .TitleBox__content {
      grid-row: 2;
      grid-column: span 1;
      padding-top: 32px;
      padding-bottom: 40px;
    }
    .TitleBox__slider {
      position: relative;
      grid-row: 1;
      grid-column: span 1;

      padding-top: 50%;
    }
    .TitleBox__image,
    .TitleSlider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

  }
  @media (max-width: $bp-mobile-max-width) {

    .TitleBox__content {
      padding-top: 24px;
      padding-bottom: 32px;
    }
    //.TitleBox__image {
    //  border-radius: 0;
    //}

  }
}
