@import "../../../vars";

.EventsIndicatorBox {

}
.EventsIndicatorBox__main {
  padding: 32px var(--content-side-padd) 0;
}

.EventsIndicatorBox__title {
  @extend %flex;
  align-items: center;
}

.EventsIndicatorBox__date {
  @extend %flex;
  align-items: center;
  margin-left: 40px;

  p {
    margin: 0;
  }
}
.EventsIndicatorBox__dateItem {
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
}

.EventsDate {
  @extend %flex;
  align-items: center;
  opacity: .5;
}
.EventsDate__icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  @extend %svg-icon;
}
.EventsDate__text {
  p {
    font-weight: 500;
    line-height: 24px;
  }
}

.EventsIndicatorBox__indicator {
  padding-top: 28px;
  padding-bottom: 35px;
}

.EventsIndicatorBox__sides {
  @extend %flex;
  justify-content: center;
  padding: 12px 0;
  border-top: 1px solid rgba($col-gray-d, .2);
}
.EventsIndicatorBox__sidesGrid {
  @extend %grid-center;
  width: 100%;
  max-width: var(--grid-center-width);
}
.EventsIndicatorBox__side {
  grid-column: span 4;
  text-align: center;

  p {
    font-weight: 700;
    line-height: 24px;
    margin: 0;
  }

  &._opposite {
    color: $col-red;
  }
  &._government {
    color: $col-blue;
  }
}


.EventsIndicatorFlyBox {
  &._fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--zindex-indicator);
    transform: translateY(-100%);
    transition: transform .3s;
    will-change: transform;

    margin-top: var(--topbar-height);
    border-top: 1px solid rgba($col-gray-d, .2);
    background: $col-white;

    &._visible {
      box-shadow: 0 8px 24px 0 rgba(39,44,58,0.05);
      transform: translateY(0);
    }

    &._pinTop {
      margin-top: 0!important;
    }

    .EventsIndicatorBox__indicator {
      padding: 20px 0 22px;
    }
  }
}


@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    .EventsIndicatorBox__main {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .EventsIndicatorBox__title {
      display: block;
    }
    .EventsIndicatorBox__date {
      margin-top: 6px;
      margin-left: 0;
    }
    .EventsIndicatorBox__dateItem {
      margin-right: 12px;
    }

    .EventsIndicatorBox__side {
      p {
        font-size: 13px;
        line-height: 16px;
      }
    }

  }
}
