@import '../../../vars';


.EventsIndicator {
  //max-width: 100%;
  margin-top: -25px;
  margin-bottom: -25px;
}
.EventsIndicator__scroll {
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
.EventsIndicator__wrapper {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
  padding: 0 var(--content-side-padd);
}
.EventsIndicator__line {
  @extend %flex;
  justify-content: center;
  padding: 25px 0;
}
.EventsIndicator__dots {
  position: relative;
  @extend %flex;
  align-items: center;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: calc(var(--event-dot-offset-width) / 2);
    right: calc(var(--event-dot-offset-width) / 2);
    height: 2px;
    margin-top: -1px;
    background: $col-gray-d;
    opacity: .3;
  }
}

.EventsIndicator__dot {
  position: relative;
  margin-right: 42px;
  &:last-child {
    margin-right: 0;
  }
}
.EventsIndicator__dotIcon {
  &:before {
    background: $col-white;
  }
}
.EventsIndicator__dotDate {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  span {
    font-size: 14px;
  }
}
