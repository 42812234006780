@import "../../vars";

.Input {

}
.Input__inp {
  @extend %input-reset;

  display: block;
  width: 100%;
  height: var(--input-height);
  padding: 0 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  background: $col-gray2;
  font-weight: 500;
  line-height: 24px;
  color: $col-dark;

  @include placeholder {
    text-overflow: ellipsis;
    transition: color .3s;
  }
  &:focus {
    @include placeholder {
      text-overflow: clip;
      color: transparent!important;
    }
  }
}
