@import "../../../vars";

.AnalyticsBox {
  @extend %grid-default;
  width: 100%;
}
.AnalyticsBox__content {
  grid-column: span 7;
  padding: 24px var(--content-side-padd);
  padding-right: 16px;
}
.AnalyticsBox__infoList {
  @extend %list-simple;
}
.AnalyticsBox__infoItem {
  border-bottom: 1px solid rgba($col-gray-d, .2);

  &:last-child {
    border-bottom: 0;
  }
}

.AnalyticsBox__ext {
  grid-column: span 5;
  padding: 32px 44px;
}
.AnalyticsBox__extTitle {
  margin-bottom: 24px;
}


@media only screen {
  @media (max-width: $bp-tablet-max-width) {

    .AnalyticsBox {
      grid-template-rows: auto auto;
      grid-template-columns: 100%;
      grid-row-gap: 0;
    }
    .AnalyticsBox__content {
      grid-row: 1;
      grid-column: span 1;
      padding: 24px var(--content-side-padd) 20px;
    }
    .AnalyticsBox__infoItem {
      &:last-child {
        border-bottom: 1px solid rgba($col-gray-d, .2);
      }
    }
    .AnalyticsBox__ext {
      grid-row: 2;
      grid-column: span 1;
      padding: 20px var(--content-side-padd) 40px;
    }

  }
  @media (max-width: $bp-mobile-max-width) {

    .AnalyticsBox__content {
      padding-top: 18px;
      padding-bottom: 16px;
    }

  }
}
