@import '../../vars';

.Select {
  //width: 200px;
  color: $col-blue;

  --height: 48px;

  .Select__content {
    white-space: nowrap;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Select__multiCount {
    margin: 0 2px 0 0;
    font-size: 14px;
    line-height: 18px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Select__input {
    color: inherit;
    input {
      color: inherit;
      font-family: inherit !important;
      font-size: 14px !important;
      line-height: 18px;
    }
  }

  :global {
    .select-container {
      height: 100%;
    }
    .select__control {
      height: 100%;
      border: none;
      box-shadow: none;
      cursor: pointer;
      margin-bottom: -1px;

      &.select__control--menu-is-open {
        .select__dropdown-indicator {
          transform: rotate(-180deg);
        }
      }
    }
    .select__value-container {
      position: relative;
      padding: 0;
      padding-right: 20px;
      border-bottom: 1px solid $col-blue;

      > div {
        margin: 0;
        padding-top: 0;
        color: inherit;
      }

      &.has-placeholder {
        &.select__value-container--has-value,
        &.has-input-value {
          padding-top: 16px;
          .select__single-value {
            transform: none;
          }
          .select__placeholder {
            margin: 0;
            top: 10px;
            font-size: 12px;
          }
        }
      }
    }
    .select__indicators {
      position: absolute;
      top: 50%;
      right: 0;
      width: 20px;
      height: 20px;
      margin-top: -11px;
    }
    .select__dropdown-indicator {
      padding: 0;
      width: 100%;
      height: 100%;
      transition: transform .2s;
    }
    .select__control--is-focus {
      border: none;
      box-shadow: none;
    }
    .select__control--is-disabled {
      opacity: 0.5;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__option {
      position: relative;
      min-height: var(--height);
      display: flex;
      align-items: center;
      font-family: var(--fonts-default);
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 0 20px;
      //line-height: var(--height);
      color: $col-blue;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;

      &.select__option--is-selected {
        background: $col-gray;
      }
      &.select__option--is-focused {
        background: $col-gray2;
      }

      + .select__option {
        border-top: 1px solid rgba($col-gray-d, .2);
      }
    }
    .select__menu-list {
      border-radius: 8px;
      padding: 0;
      min-width: 140px;
    }
    .select__menu {
      width: auto;
      min-width: calc(100% + 40px);
      padding: 0;
      margin-left: -20px;
      border-radius: 8px;
    }
    .select__placeholder,
    .select__single-value {
      font-size: 16px;
    }
    .select__single-value {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $col-blue;
      margin: 0;

      position: relative;
      max-width: none;
      top: 0;
      transform: none;
    }
    .select__placeholder {
      opacity: 0.5;
      transition: top 0.1s, font-size 0.1s;
    }
  }



  .Select__dropDown {
    width: 20px;
    height: 20px;
    color: $col-blue;

    &:hover {
      color: $col-blue;
    }

    &:active,
    &:focus {
      color: $col-blue;
    }
  }

}
