@import "../../vars";

.RightPanel__offset {
  position: fixed;
  z-index: var(--zindex-right-panel-offset);
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: #090E19;
  opacity: 0;
  transition: opacity .3s;
  will-change: opacity;

  &._active {
    width: 100%;
    opacity: .8;
  }
}

.RightPanel {
  position: fixed;
  z-index: var(--zindex-right-panel-offset);
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: var(--right-panel-width);
  background: $col-gray2;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity .3s, transform .3s;
  will-change: opacity;

  &._active {
    transform: translateX(0);
    opacity: 1;
  }
}
.RightPanel__wrapper {
  //display: flex;
  //flex-direction: column;
  //display: grid;
  //grid-template-rows: auto minmax(auto, 1fr);
  height: 100%;
  width: 100%;
}
.RightPanel__top {
  //grid-row: 1 / span 1;
  @extend %flex-btw;
  align-items: flex-start;
  width: 100%;
  padding: 24px var(--right-panel-side-padd);
  border-bottom: 1px solid rgba($col-gray-d, .2);
}
.RightPanel__main {
  //grid-row: 2 / span 1;
  //flex: 1;
  width: 100%;
  //height: 100%;
}
.RightPanel__scroll {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.RightPanel__title {
  width: 100%;
  padding-right: 16px;
  color: $col-dark;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
}
.RightPanel__closeBtn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-top: 4px;
  cursor: pointer;
  @extend %svg-icon;

  i {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.RightPanel__content {
  padding: 16px var(--right-panel-side-padd);
  line-height: 20px;

  p {
    margin: 8px 0;
  }
  ul, ol {
    margin: 8px 0;
  }
  ul {
    list-style-type: disc;
  }
}
.RightPanel__slider {
  width: 100%;//400px;
  margin-bottom: 24px;
}

.RightPanel__photo,
.RightPanelSlider {
  width: 100%;
  height: 264px;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.RightPanel__photo {
  background-repeat: no-repeat;
  background-size: cover;
}


@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    .RightPanel {

    }

  }
}
