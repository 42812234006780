@import "../../vars";

.IndexPage {
  padding-top: 24px;
}
.IndexPage__main {
  grid-column: 1 / span 12;
  width: 100%;
  overflow: hidden;
}

.IndexPage__analytics {
  grid-column: 1 / span 12;
  width: 100%;
}

.IndexPage__eventsLoader {
  grid-column: 1 / span 12;
}

.IndexPage__eventsIndicator {
  grid-column: 1 / span 12;
  width: 100%;
}

.IndexPage__eventsList {
  grid-column: 1 / span 12;
  width: 100%;
  padding-top: calc(40px - var(--grid-row-gap));
}
