@import "../../vars";

.Slider {
  position: relative;
}
.Slick {
  position: relative;
  @extend %flex-c;
  width: 100%;

  & :global {
    .slick-list {
      width: 100%!important;
      overflow: hidden;
    }
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-slide {
      position: relative;
    }
    .slick-arrow {
      position: absolute;
    }
  }
}
