@import "../../vars";

.MainLayout {
  padding-top: var(--topbar-height);
  height: 100%;
}
.MainLayoutWithoutTopbar {
  padding-top: 0;
  height: 100%;
}
.MainLayout__main {
  @extend %flex;
  justify-content: center;
  height: 100%;
}
.MainLayout__grid {
  @extend %grid-default;
  width: 100%;
  height: 100%;
  max-width: var(--grid-width);
}

.LayoutBox {
  background: $col-white;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgba(39,44,58,0.05);
}


@media only screen {
  @media (max-width: $bp-mobile-max-width) {

    .MainLayout {
      padding-top: calc(var(--topbar-height) * 2);
    }


    .LayoutBox {
      border-radius: 0;
    }

  }
}
