@import "../../vars";

.Button {
  @extend %button-reset;
  @extend %flex-c;
  height: var(--button-height);
  padding: 0 16px;
  border-radius: 8px;

  &._primary {
    background: $col-blue;
    color: $col-white;
  }
}
.Button__text {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
